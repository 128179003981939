@import "~@station/styles/main.scss";

body::-webkit-scrollbar {
  width: 9px;
}

body::-webkit-scrollbar-thumb {
  background-color: #f37021;
  border-radius: 6px;
}

body {
  scrollbar-width: thin;
}

body::-webkit-scrollbar-track {
  background-color: #f1f1f1;
}

body .cdk-overlay-container{
  width: 50% !important
}

.info-container .info-icon{
  display: none !important;
}

.bg-box-color {
  background-color: #fff !important;
}

.content-icon ul {
  position: fixed;
}

.toggle-active {
  position: fixed !important;
}

.toggle-active ul {
  position: relative;
  top: 68px;
}

.header {
  position: sticky;
  top: 0;
  z-index: 1000;
}

.btn-label {
  display: inline-block;
  padding: 5px 10px;
  background: #e6e6e6;
  margin-right: 10px;
  border-radius: 5px;
  cursor: pointer;
}

.mat-ink-bar {
  background-color: #ff9300 !important;
}

.mat-ripple-element {
  display: none !important;
  z-index: 1000 !important;
}

.station-btn {
  margin-top: 30px;
}

.station-btn {
  button,
  input {
    background-color: transparent;
    width: 10%;
    border: 1px solid #ff9300;
    border-radius: 2px;
    font-size: 12px;
    font-weight: bold;
    transition: all 0.5s cubic-bezier(0.165, 0.84, 0.44, 1);
  }
}

.station-btn {
  button:hover,
  input:hover {
    background-color: #ff9300;
    color: white;
  }
}

/* Simula o menu */
.notification-menu {
  position: absolute;
  top: 40px;
  right: 10px;
  width: 300px;
  max-height: 500px;
  overflow: auto;
  background: #fff;
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 10px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  cursor: pointer;
}

.notification-menu.show {
  display: block;
}

.notification-menu-title {
  font-size: 1.2em;
  margin-bottom: 10px;
}

.notification-menu-item {
  padding: 10px 0;
  border-bottom: 1px solid #f7f2f2;
  display: flex;
  align-items: center;
}

.notification-icon-unread {
  color: var(--bs-yellow) !important;
}
.notification-icon-priority {
  color: var(--danger) !important;
}
.notification-icon {
  font-size: 1.2em;
  font-family: Material Icons;
  color: #ccc;
  margin-right: 10px;
}

.notification-menu-item:last-child {
  border-bottom: none;
}

.notification-menu-item:hover {
  background-color: #f4f4f4;
}

.notification-menu-item-content {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.notification-menu-item-title {
  font-weight: bold;
  max-width: 200px;
  font-size: 0.9em;
}

.notification-menu-item-message {
  margin-top: 5px;
  font-size: 0.8em;
  max-width: 200px;
  color: #999;
}

.notification-menu-item-time {
  color: #999;
  font-size: 0.7em;
  margin-top: 5px;
}
// .mat-step-header .mat-step-icon {
//   background-color: transparent !important;
// }
.mat-step {
  font-size: 0.9em;
}
.mat-step-icon-content {
  font-size: 0.6em !important;
}
.step-label-details {
  font-weight: 800;
}
